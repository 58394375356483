<!--
TODO:
    - PROP: Timeout interval: hide modal on timeout
    - PROP: Pass option to show an error modal in error color onTimeout
    - PROP: Pass some styles / Margins / Custom Spinner perhaps
    - PROP: Option to be able to dismiss manually
-->
<template>
    <div
        class="dimmer-container position-relative"
    >
        <slot
            name="content"
            :toggle="onToggle"
        ></slot>
        <v-fade-transition>
            <div
                v-if="dialog"
                class="dimmer-modal fill-height fill-width align-center justify-center text-center pa-6"
            >
                <div
                    class="dimmer-modal-spinner"
                    :class="{ 'position-fixed': fixedSpinner }"
                >
                    <div
                        v-if="!hideSpinner"
                        class="d-flex fill-height fill-width align-center justify-center mb-6"
                    >
                        <v-progress-circular
                            :color="color"
                            :size="size"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                    <div
                        class="d-flex fill-height fill-width align-center justify-center"
                    >
                        <slot
                            name="message"
                        ></slot>
                    </div>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script lang="ts" setup>
    const props = defineProps({
        modelValue: {
            type: Boolean,
            default: false
        },
        hideSpinner: {
            type: Boolean,
            default: false
        },
        fixedSpinner: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: Number,
            default: 1
        },
        color: {
            type: String as PropType<string>,
            default: 'primary'
        },
        size: {
            type: [String, Number] as PropType<string | number>,
            default: 42
        }
    });

    const emit = defineEmits<{
        (e: 'update:modelValue', state: boolean): void;
    }>();
    
    const dialog = ref(props.modelValue);

    const unWatch = watch(
        () => props.modelValue,
        (val) => {
            dialog.value = val;
        }
    );

    const onToggle = (state: boolean) => {
        dialog.value = state ?? !dialog.value;
        emit('update:modelValue', dialog.value);
    };

    onBeforeUnmount(() => {
        unWatch();
    });
</script>

<style lang="scss">
.dimmer-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: v-bind(zIndex);
    background-color: rgba(var(--v-theme-surface), 0.4);

    &-spinner {
        position: sticky;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
    }
}
</style>
